<!--
  PACKAGE_NAME : src\pages\ewm\demo\list.vue
  FILE_NAME : list
  AUTHOR : bykim
  DATE : 2024-01-29
  DESCRIPTION : EWM 컴포넌트 데모 페이지
-->
<template>
  <div class="container">
    <Tabs ref="tabs" @selectedIndex="tabSelectedIndex" :tabType="1">
      <Tab title="모달 상담원정보" >
        <ModalAgentInfoTest ref="modalAgentInfoTestRef" v-if="tabIndex === 0"/>
      </Tab>
      <Tab title="DX 컴포넌트 테스트">
        <DefaultDxComponentTest ref="defaultDxComponentTestRef" v-if="tabIndex === 1"/>
      </Tab>
      <Tab title="ENUM 공통 코드 페이지">
        <CommonEnumCodes ref="commonEnumCodesRef" v-if="tabIndex === 2"/>
      </Tab>
    </Tabs>
  </div>
</template>

<script>

import Tab from "@/components/common/tab.vue";
import Tabs from "@/components/common/tabs.vue";
import ModalAgentInfoTest from "@/pages/ewm/demo/modal-agent-info-test.vue";
import DefaultDxComponentTest from "@/pages/ewm/demo/default-dx-component-test.vue";
import CommonEnumCodes from "@/pages/ewm/demo/common-enum-code-test.vue";

export default {
  components: {CommonEnumCodes, DefaultDxComponentTest, ModalAgentInfoTest, Tabs, Tab},
  props: {},
  data() {
    return {
      tabIndex: 0,
      tabs: {
        selectedIndex: this.$store.getters.getTabHists[this.$router.currentRoute.path],
      },
    }
  },
  computed: {},
  methods: {
    async tabSelectedIndex(index) {
      this.tabIndex = index;
    },
  },
  created() {
  },
  mounted() {
  },
}
</script>

<style lang="scss" scoped>

</style>